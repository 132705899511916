import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import { Box } from '@3pc/layout-components-react';
import { footerHeight } from '../components/Footer';
import { headerHeight } from '../components/PageHeader';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import ListItemLink from '../base/ListItemLink';
import Loupe from '../../static/icons/loupe-bold.svg';
import Close from '../../static/icons/close.svg';
import { devices } from '../styles/breakpoints';
import { colors } from '../styles/colors';
import { sortItems } from '../utils';

const SearchInput = styled.input`
  background: ${colors['blue-rgba-one']};
  padding: 12px;
  padding-left: 60px;
  font-size: 1em;
  width: 100%;
  height: 48px;
  caret-color: ${colors.blue};
  box-shadow: inset 0px 0px 0px ${colors['blue-rgba-seven']};
  transition: all 200ms ease-in-out;

  @media ${devices.mobileMin} {
    padding: 0 15%;
    padding-left: calc(15% + 9px + 24px + 5px);
    height: 72px;
  }

  @media ${devices.nonTouchable} {
    &:hover {
      box-shadow: inset 0px 0px 5px ${colors['blue-rgba-seven']};
    }
  }

  &:focus-visible {
    box-shadow: inset 0px 0px 5px ${colors['blue-rgba-seven']};
  }

  &:active {
    background: ${colors['blue-rgba-three']};
  }

  &:focus {
    outline: 0;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    position: absolute;

    &:first-of-type {
      left: 15px;

      @media ${devices.mobileMin} {
        left: calc(15% - 5px);
      }
    }

    &:last-of-type {
      right: 20px;

      @media ${devices.mobileMin} {
        right: calc(15% + 20px);
      }
    }
  }
`;

const Results = styled.ol`
  padding-left: 20px;

  @media ${devices.mobileMin} {
    margin: 0 15%;
    padding-left: 0;
  }
`;

const Topic = styled.li`
  margin-top: 20px;

  h2 {
    color: ${colors.blue};
  }

  @media ${devices.mobileMin} {
    margin-top: 28px;
  }
`;

const SearchList = styled.ol`
  margin-top: 10px;

  @media ${devices.mobileMin} {
    margin-top: 20px;
  }

  li a span {
    font-style: normal;
  }
`;

const NoResult = styled.p`
  text-align: center;
  margin-top: 20px;

  @media ${devices.mobileMin} {
    margin-top: 28px;
  }
`;

const Button = styled.button`
  background: transparent;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 200ms ease-in-out;

  @media ${devices.mobileMin} {
    padding: 10px;
  }

  @media ${devices.nonTouchable} {
    &:hover {
      background: ${colors['blue-rgba-three']};
    }
  }

  &:focus-visible {
    background: ${colors['blue-rgba-three']};
  }

  &:active {
    background: ${colors['blue-rgba-seven']};
  }

  &:focus {
    outline: 0;
  }
`;

export const query = graphql`
  query {
    localSearchPages {
      index
      store
    }
  }
`;

const SearchPageTemplate = ({ data }) => {
  const index = data.localSearchPages.index;
  const store = data.localSearchPages.store;
  const [hasSearchOnce, setHasSearchOnce] = useState(false);
  const [input, setInput] = useState('');
  const [query, setQuery] = useState(null);
  const results = useFlexSearch(query, index, store);
  const [articles, setArticles] = useState([]);
  const [lexiconPages, setLexiconPages] = useState([]);
  const [backgroundPages, setBackgroundPages] = useState([]);

  function handleSubmit(e) {
    e.preventDefault();
    setQuery(input);
    setHasSearchOnce(true);
  }

  useEffect(() => {
    let articlesArray = [];
    let lexiconPagesArray = [];
    let backgroundPagesArray = [];

    results.length &&
      results.forEach((result) => {
        const instanceName = result.sourceInstanceName;

        if (instanceName === 'articles') {
          articlesArray.push(result);
        }

        if (instanceName === 'lexiconpages') {
          lexiconPagesArray.push(result);
        }

        if (instanceName === 'backgroundpages') {
          backgroundPagesArray.push(result);
        }
      });

    setArticles(articlesArray);
    setLexiconPages(lexiconPagesArray);
    setBackgroundPages(backgroundPagesArray);
  }, [results]);

  return (
    <>
      <PageHeader headerTitle={'Suche'} pageType={'list-page'} />
      <Layout>
        <Box
          as="form"
          role="search"
          action="/"
          method="get"
          id="search"
          mb={footerHeight.footerMobile}
          mt={headerHeight.headerMobile}
          onSubmit={(e) => handleSubmit(e)}
        >
          <label htmlFor="header-search">
            <span className="sr-only">Suche</span>
          </label>
          <InputWrapper>
            <Button type="submit">
              <Loupe width="24px" height="24px" />
              <span className="sr-only">Suche abschicken</span>
            </Button>
            <SearchInput
              type="text"
              id="header-search"
              onChange={(e) => setInput(e.target.value)}
            />
            <Button type="reset" onClick={(e) => setInput(e.target.value)}>
              <Close width="12px" height="12px" />
            </Button>
          </InputWrapper>
          {results.length ? (
            <Results>
              {articles.length ? (
                <Topic>
                  <h2>Verfassung</h2>
                  <SearchList>
                    {articles
                      .sort((a, b) => {
                        return sortItems(a.title, b.title);
                      })
                      .map((result, index) => (
                        <ListItemLink
                          key={`article-result-${index}`}
                          link={`/${result.slug}`}
                          info={result.title}
                        />
                      ))}
                  </SearchList>
                </Topic>
              ) : (
                ''
              )}
              {lexiconPages.length ? (
                <Topic>
                  <h2>Lexikon</h2>
                  <SearchList>
                    {lexiconPages.map((result, index) => (
                      <ListItemLink
                        key={`lexicon-result-${index}`}
                        link={`/${result.slug}`}
                        info={result.title}
                      />
                    ))}
                  </SearchList>
                </Topic>
              ) : (
                ''
              )}
              {backgroundPages.length ? (
                <Topic>
                  <h2>Hintergrund</h2>
                  <SearchList>
                    {backgroundPages.map((result, index) => (
                      <ListItemLink
                        key={`hintergrund-result-${index}`}
                        link={`/${result.slug}`}
                        info={result.title}
                      />
                    ))}
                  </SearchList>
                </Topic>
              ) : (
                ''
              )}
            </Results>
          ) : hasSearchOnce ? (
            <NoResult>Keine Ergebnisse</NoResult>
          ) : (
            ''
          )}
        </Box>
      </Layout>
      <Footer />
    </>
  );
};

export default SearchPageTemplate;
